import chords                                           from 'framework/resources/json/chord-ref'
import lineRef                                          from 'framework/resources/json/line-ref'
import { getConstants, getNotesInRange, transposeNote } from 'framework/helpers/note-art-helpers'
import { Frequency }                                    from 'tone'

/**
 * Transforms the first letter of a string to upper case.
 * @param {String} str String to transform
 * @returns {String}
 */
export function firstToUpper(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function fallbackCopyTextToClipboard(text) {
  var textArea   = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top      = '0'
  textArea.style.left     = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg        = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
    return true
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
    return false
  } finally {
    document.body.removeChild(textArea)
  }
}

/**
 *  Copy string to clipboard
 * @param text
 */
export async function copyTextToClipboard(text) {
  if( !navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }
  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Returns information about a chord from chords.json by pattern
 * @param pattern
 * @returns {{name: string, chord: string, pattern: []}}
 */
export function getChordInfo(pattern) {
  for(const chord of chords) {
    if(arraysEqual(pattern, JSON.parse(chord.pattern))) {
      return {
        name:  chord.name,
        chord: chord.chord,
        pattern
      }
    }
  }

  return {
    name:  '--',
    chord: '--',
    pattern
  }
}

/**
 * Checks whether 2 arrays are equal.
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export function arraysEqual(arr1, arr2) {
  if( !isSameLength(arr1, arr2)) {
    return false
  }
  for(let i = 0; i < arr1.length; ++i) {
    if(arr1[i] !== arr2[i]) {
      return false
    }
  }

  return true
}

/**
 * Checks whether 2 arrays have the same length.
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export function isSameLength(arr1, arr2) {
  return arr1.length === arr2.length
}

/**
 * Returns a line from lines.json by name.
 * @param lineName
 */
export function getLine(lineName) {
  const line = lineRef[lineName]
  if(line) {
    return line
  }
  throw new Error(`The line ${ lineName } does not exist!`)
}

/**
 *
 * @param line
 * @param position
 * @param index
 * @param existingNotes
 * @returns {boolean}
 */
export function filterByLine(line, position, index, existingNotes) {
  const value = line[position % line.length]
  if( !value.filter) {
    if( !anyElementInArray(value.notes, existingNotes)) {
      line.splice(position % line.length)
      return filterByLine(line, position, index, existingNotes)
    }
    return value.notes.includes(index)
  } else if(value.filter === '-') {
    return !value.notes.includes(index)
  } else {
    return true
  }
}

/**
 * Returns random element from array.
 * @param arr
 * @returns {*}
 */
export function getRandomElementFromArray(arr) {
  return arr[Math.floor(Math.random() * arr.length)]
}

/**
 * Returns true if any of the elements in an array exist in the other array, otherwise false.
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
export function anyElementInArray(arr1, arr2) {
  return arr1.some(el => arr2.includes(el))
}

/**
 * Returns an array of an interpolation of colors.
 * @param color1
 * @param color2
 * @param steps
 * @returns {[]}
 */
export function interpolateColors(color1, color2, steps) {
  const stepFactor             = 1 / (steps - 1)
  const interpolatedColorArray = []

  color1 = color1.match(/\d+/g).map(Number)
  color2 = color2.match(/\d+/g).map(Number)

  for(let i = 0; i < steps; i++) {
    interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i))
  }

  return interpolatedColorArray
}

/**
 * Returns the interpolated color between two colors by a factor.
 * @param color1
 * @param color2
 * @param factor
 * @returns {*}
 */
function interpolateColor(color1, color2, factor) {
  if(arguments.length < 3) {
    factor = 0.5
  }
  const result = color1.slice()
  for(let i = 0; i < 3; i++) {
    result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]))
  }
  return result
}

/**
 * Turns any sharp pitch class to flat.
 * @param {String} pitchClass
 * @returns {String}
 */
export function toFlatPitchClass(pitchClass) {
  if(pitchClass.includes('#')) {
    return getConstants().flatClassNotes[getConstants().sharpClassNotes.indexOf(pitchClass)]
  }

  return pitchClass
}

/**
 * Turns any sharp note to flat.
 * @param {String} note
 * @returns {string}
 */
export function toFlatNote(note) {
  if(note.includes('#')) {
    const { pitchClass, octave } = noteToObject(note)
    return `${ toFlatPitchClass(pitchClass) }${ octave }`
  }
  return note
}

/**
 * Turns a note to an object.
 * @param note
 * @returns {{octave: number, pitchClass: String}}
 */
export function noteToObject(note) {
  return {
    pitchClass: note.slice(0, note.length - 1),
    octave:     parseInt(note[note.length - 1])
  }
}

/**
 * Returns an array of notes from a base note and array of intervals.
 * @param {String} baseNote
 * @param {Array<Number>} intervals
 * @returns {Array<String>}
 */
export function intervalsToNotes(baseNote, intervals) {
  return intervals.map(interval => {
    const semitones = toSemitones(interval)
    return transposeNote(baseNote, semitones)
  })
}

/**
 * Returns true if string represents a number, else false.
 * @param {String} str
 * @returns {boolean}
 */
export function isNumberString(str) {
  return isNaN(parseInt(str))
}

/**
 * Normalize any interval representation to a semitone of Number type.
 * @param {*} interval
 * @returns {number}
 */
export function toSemitones(interval) {
  let semitones
  if(typeof interval === 'number') {
    semitones = interval
  } else {
    if(isNumberString(interval)) {
      semitones = getConstants().intervals[interval]
    } else {
      semitones = parseInt(interval)
    }
  }
  return semitones
}

/**
 * Returns the max interval from an array of intervals.
 * @param {Array} intervals
 * @returns {number}
 */
export function maxInterval(intervals) {
  let max = -Infinity
  intervals.forEach(interval => {
    const curr = toSemitones(interval)
    max        = curr > max ? curr : max
  })
  return max
}

/**
 * Returns the highest note between 2 notes.
 * @param {String} note1
 * @param {String} note2
 * @returns {String}
 */
export function highestNote(note1, note2) {
  return lowestNote(note1, note2) === note1 ? note2 : note1
}

/**
 * Returns the lowest note between 2 notes.
 * @param {String} note1
 * @param {String} note2
 * @returns {String}
 */
export function lowestNote(note1, note2) {
  const noteObj1 = noteToObject(note1)
  const noteObj2 = noteToObject(note2)
  if(noteObj1.octave < noteObj2.octave) {
    return note1
  } else if(noteObj1.octave > noteObj2.octave) {
    return note2
  } else {
    const pitchClass = lowestPitch(noteObj1.pitchClass, noteObj2.pitchClass)
    return `${ pitchClass }${ noteObj1.octave }`
  }
}

/**
 * Returns the lowest pitch between 2 pitch classes.
 * @param {String} pc1
 * @param {String} pc2
 * @returns {String}
 */
export function lowestPitch(pc1, pc2) {
  const { pitchClasses } = getConstants()
  return pitchClasses.indexOf(pc1) <= pitchClasses.indexOf(pc2) ? pc1 : pc2
}

/**
 * Returns the lowest note from an array of notes.
 * @param {Array} notes
 * @returns {String}
 */
export function lowestNoteFromArray(notes) {
  return notes.reduce((acc, curr) => lowestNote(acc, curr), notes[0])
}

/**
 * Returns the highest note from an array of notes.
 * @param {Array} notes
 * @returns {String}
 */
export function highestNoteFromArray(notes) {
  return notes.reduce((acc, curr) => highestNote(acc, curr), notes[0])
}

/**
 * Turns a midi value to frequency.
 * @param {Number} midi
 * @returns {number}
 */
export function frequencyFromMidi(midi) {
  return 440 * Math.pow(2, (midi - 69) / 12)
}

/**
 * Turns a frequency value to midi note.
 * @param frequency
 * @returns {number}
 */
export function frequencyToFloatMidi(frequency) {
  return 69 + 12 * Math.log2(frequency / 440)
}

/**
 * Turns frequency value to a ABSOLUTE midi note.
 * @param {Number} frequency
 * @returns {number}
 */
export function frequencyToMidi(frequency) {
  return Math.round(frequencyToFloatMidi(frequency))
}

/**
 * Returns how much cents off a frequency is from an absolute note.
 * @param {Number} frequency
 * @param {Number} midi
 * @returns {number}
 */
export function centsOffFromFrequency(frequency, midi) {
  return Math.floor(1200 * Math.log(frequency / frequencyFromMidi(midi)) / Math.log(2))
}

/**
 * Returns an array from a string of values seperated by spaces.
 * @param {String} str
 * @returns {Array}
 */
export function stringPatternToArray(str) {
  return str.split(' ')
}

/**
 * Maps a value from range A to range B.
 * @param value
 * @param x1
 * @param y1
 * @param x2
 * @param y2
 * @returns {Number}
 */
export const mapToRange = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2

/**
 * Maps a value from decibels to a regular 0-100 range.
 * @param value
 * @returns {Number}
 */
export function mapFromDecibels(value) {
  return mapToRange(value, -90, 12, 0, 100)
}

/**
 * Maps a value from 0-100 range to decibels.
 * @param value
 * @returns {Number}
 */
export function mapToDecibels(value) {
  return Math.ceil(mapToRange(value, 0, 130, -15, 6))
}

/**
 * Returns an array of pitches with their midi values.
 * @param base Note to start from
 * @param range Number of notes
 * @returns {{pitch: *, midiNote: number}
 */
export function getFrequencyTableFromPitches(base = 'A0', range = 87) {
  return Object.keys(getNotesInRange(base, range)).map(pitch => {
    return {
      midiNote: Frequency(pitch).toMidi(),
      pitch
    }
  })
}

export function median(arr) {
  const mid  = Math.floor(arr.length / 2),
        nums = [...arr].sort((a, b) => a - b)
  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
}

export function medianFilterSingle(arr, i, range, filterCallback) {
  const tmpArray = []
  for(let j = i - range; j < i + range; ++j) {
    if(filterCallback(arr[j])) {
      tmpArray.push(arr[j])
    }
  }
  return median(tmpArray)
}

export function medianFilterArray(arr, range) {
  return arr.map((val, i) => {
    const tmpArray = []
    for(let j = i - range; j < i + range; ++j) {
      if(arr[j]) {
        tmpArray.push(arr[j])
      }
    }
    return median(tmpArray)
  })
}

/**
 * Returns an array of arrays where each inner array has a list of objects with x and y values.
 * Each member of the data array should be an object which must be one of:
 * 1. Empty -> Marks the start of a new poly line
 * 2. frequency, time and midi properties.
 * @param {Array} data The data to create the poly lines from
 * @returns {[]}
 */
export function getPolyLinesFromData(data) {
  const polyLines     = []
  let currentPolyline = []
  data.forEach(({ time, midi }) => {
    if( !midi) {
      if(currentPolyline) {
        polyLines.push(currentPolyline)
      }
      currentPolyline = []
    } else {
      currentPolyline.push({ x: time, y: midi })
    }
  })
  if(currentPolyline) {
    polyLines.push(currentPolyline)
  }
  return polyLines
}

/**
 * Returns am array containing x and y coordinate objects that represent a polyline created from the data.
 * The polyline is created as a staircase shape and is used for getting poly lines for data of vocal exercises or the like.
 * @param {Array<Object>} data Array of objects containing time and midi values.
 * @returns {[]}
 */
export function getStairsPolyLineFromData(data) {
  const polyLine = []
  data.forEach(({ time, midi }, index) => {
    const prev = data[index - 1]
    if(prev) {
      polyLine.push({ x: time, y: prev.midi })
    }
    polyLine.push({ x: time, y: midi })
  })
  return polyLine
}

// export function normalizePitchDetectionsData(data, range){
//   return data.map((curr, i) => {
//     const tmpArray = []
//     for(let j = i - range; j < i + range; ++j) {
//       if(data[j]) {
//         tmpArray.push(data[j])
//       }
//     }
//     return median(tmpArray)
//   })
// }

/**
 * Remove adjacent duplicates from array using a specific callback.
 * @param arr The array to remove duplicates from.
 * @param callback The callback to invoke on each 2 adjacent elements.
 * @example
 * const arr = [1,1,2,2,2,1,1,2,1,1,]
 * removeAdjacentDuplicates(arr, (el1, el2) => el1 === el2) // [1,2,1,2,1]
 * @returns {Array}
 */
export function removeAdjacentDuplicates([...arr], callback) {
  let i = 0
  while(i < arr.length) {
    if(arr[i + 1] && callback(arr[i], arr[i + 1])) {
      arr.splice(i + 1, 1)
    } else {
      ++i
    }
  }
  return arr
}

/**
 * If data is an array returns it, otherwise returns a new array with the data in it.
 * @param {*} data
 * @returns {[]}
 */
export function toArray(data) {
  return Array.isArray(data) ? data : [data]
}