import {
  calculateInterval, Chord, Constants, extractOctave, extractPitchClass, ModelHelper, notesInRange, PitchClass,
  rearrangeArray, Scale, Score, ScoreHandler, transpose, isRawNote
}                                              from 'note-art'
import { filterByLine, getChordInfo, getLine } from 'framework/utilities/Utilities'
import { DEFAULT_PLAYER }                      from 'framework/resources/framework-constants'

/**
 * Create new score, all params are optional
 * @param config.name
 * @param config.timeSignature
 * @param config.bpm
 * @param config.voiceNames
 * @returns {*}
 */
export function createScore(config) {
  return new Score(config)
}

export function createScale(pitchClass, pattern, info) {
  if( !PitchClass.isPitchClass(pitchClass)) {
    return new Scale(createPitchClass(pitchClass), pattern, info)
  }
  return new Scale(pitchClass, pattern, info)
}

export function createPitchClass(pc) {
  return new PitchClass(pc)
}

export function createChord(pitchClass, pattern, info) {
  if( !PitchClass.isPitchClass(pitchClass)) {
    return new Chord(createPitchClass(pitchClass), pattern, info)
  }
  return new Chord(pitchClass, pattern, info)
}

export function generateRandomScoreName(key, scale) {
  return `${ key }_${ scale }_${ Math.round(Math.random() * 1000) }`
}

export function getScoreHandler() {
  return ScoreHandler
}

export function getConstants() {
  return Constants
}

export function transposeNote(note, interval) {
  return transpose(note, interval)
}

export function getModelHelper() {
  return ModelHelper
}

export function getRearrangeArray(arr, index) {
  return rearrangeArray(arr, index)
}

export function getNotesInRange(base, length) {
  return notesInRange(base, length)
}

export function scoreFromNotesAndPattern(notes, pattern) {
  pattern     = pattern || new Array(notes.length).fill('8n')
  const score = createScore({ voiceNames: [DEFAULT_PLAYER] })
  score.addMeasure(DEFAULT_PLAYER)
  addHarmonyToScore('Melodic Upwards', score, pattern, 0, { notes }, DEFAULT_PLAYER)
  return score
}

export function addHarmonyToScore(lineName, score, pattern, measure, data, voiceName) {
  const line          = [...getLine(lineName)]
  const existingNotes = data.notes.map((n, i) => i)
  pattern.forEach((duration, position) => {
    const notes = data.notes.filter((note, i) => filterByLine(line, position, i, existingNotes))
                      .map(note => note.toString())
    let name
    if(data.pattern) {
      name = `${ data.notes[0].raw }${ getChordInfo(data.pattern).name }`
    }
    score.addChord(voiceName, measure, position, {
      notes,
      duration,
      name
    })
  })
}

export function calculateNotesDistance(note1, note2) {
  const pc1                      = new PitchClass(extractPitchClass(note1))
  const pc2                      = new PitchClass(extractPitchClass(note2))
  const octave1                  = extractOctave(note1)
  const octave2                  = extractOctave(note2)
  const pitchClassDistance       = calculateInterval(pc1, pc2)
  const octaveDistance           = (octave2 - octave1)
  const normalizedOctaveDistance = octaveDistance - (pc2.classIndex >= pc1.classIndex ? 0 : 1)
  return normalizedOctaveDistance * 12 + pitchClassDistance
}

export function resolveNotes(notes) {
  return [...notes, transpose(notes[0], 12)]
}

/**
 * Returns true if a string is a valid representation of a musical pitch, containing a pitch class and an octave.
 * e.g 'C3'
 * @param str
 * @returns {*}
 */
export function isValidPitch(str) {
  return isRawNote(str)
}